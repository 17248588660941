<template>
  <div style="padding: 20px">
    <div>
      <h3>双花风险交易列表</h3>
      <h5>BSV网络</h5>
    </div>
    <!-- :row-key="order_id" -->
    <el-table :data="list" border stripe>
      <el-table-column label="Tx" prop="txid"></el-table-column>
      <el-table-column label="状态" prop="state">
        <template slot-scope="scope">
          <div>{{ scope.row.state }}</div>
        </template>
      </el-table-column>
      <el-table-column label="双花算力占比%" prop="double_spent_risk_hash_rate_proportion">
        <template slot-scope="scope">
          <div>{{ scope.row.double_spent_risk_hash_rate_proportion }}</div>
        </template>
      </el-table-column>
      <el-table-column label="确认数" prop="confirmations"></el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getDoubleList } from "@/api/nft.js";

export default {
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
    };
  },

  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const params = {
          page: this.page,
          page_size: this.pageSize,
        };
        const { data } = await getDoubleList(params);
        const { total = 0, list = [] } = data?.data;
        this.total = total;
        this.list = [...list];
      } catch (error) {
        console.log(error);
      }
    },

    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
  },
};
</script>
